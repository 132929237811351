<template>
  <div class="banners">
    <div class="content">
      <div class="epicentral-banner">
        <picture>
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/banner_mob.jpg
            "
            media="(max-width: 767px)"
          />
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/banner_tab.jpg
            "
            media="(max-width: 1220px)"
          />
          <source
            srcset="
              https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/banner.jpg
            "
            media="(min-width: 1221px)"
          />
          <img
            class="epicentral-banner__img"
            src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/nevro-promo/banner.jpg"
          />
        </picture>
        <div class="epicentral-banner__text">
          <div class="epicentral-banner__title">
            ВсмATTRиваемся в&nbsp;полинейропатию
          </div>
          <div class="epicentral-banner__description">
            Видео с экспертами и клинические разборы реальных пациентов помогут
            узнать больше о лабораторной и&nbsp;инструментальной диагностике,
            классификации ATTR&#8209;амилоидоза и возможностях терапии
          </div>
        </div>
      </div>
      <div class="epicentral__title">
        1. Ранняя диагностика ATTR‑амилоидоза – будущее наступило
      </div>
      <br />
      <br />
      <div class="banners__text">
        Ранняя диагностика ATTR‑амилоидоза необходима для максимального
        улучшения функций и качества жизни пациента. Классическое проявление
        наследственного ATTR‑амилоидоза с полинейропатией —дистальная
        аксонопатия, которую определяют как прогрессирующее симметричное
        поражение сенсомоторных и вегетативных длинных нервных волокон. Как
        правило, симптомы начинаются с дистальных отделов нижних конечностей
        <sup>1, 2</sup>:
        <br />
        <br />
        - онемение, боль;
        <br />
        - снижение температурной/болевой чувствительности;
        <br />
        - мышечная сила изначально сохраняется.
        <br />
        <br />
        На практике врачи-неврологи ежедневно встречают пациентов с такими
        симптомами. Уверены, что не пропустили редкое жизнеугрожающее
        заболевание? Регистрируйтесь в проекте для неврологов, посвященном
        диагностике и лечению ATTR‑амилоидоза, чтобы своевременно заподозрить
        «болезнь-убийцу» и продлить жизнь своим пациентам. Вместе с экспертами
        Вы разберете алгоритмы скрининга и лечения, изучите клинические случаи,
        узнаете о частых врачебных ошибках и многое другое.
        <a
          onclick="ym(91468266,'reachGoal','bolshe ')"
          v-if="user && Object.keys(user).length"
          href="https://amyloidosis.az-most.ru/#two"
          class="mt-4 button button_pink px-8"
          style="white-space: normal; text-align: center"
        >
          Узнать больше о диагностике ATTR‑амилоидоза
        </a>
        <a
        onclick="ym(91468266,'reachGoal','bolshe ')"
          v-else
          :href="redirectLink('#two')"
          class="mt-4 button button_pink px-8"
        >
          Узнать больше о диагностике ATTR‑амилоидоза
        </a>
      </div>
      <br />

      <div class="epicentral__title">2. Алгоритмы лечения</div>
      <br />
      <br />
      <div class="banners__text">
        ATTR‑амилоидоз — прогрессирующее заболевание, приводящее к инвалидизации
        и летальному исходу. Его следует лечить сразу же после диагностики,
        чтобы достичь наилучшего терапевтического ответа и предотвратить тяжелое
        поражение органов-мишеней. Медиана выживаемости с момента постановки
        диагноза составляет 5–15 лет при преимущественно нейропатических
        симптомах и 2,5–4 года при преобладании симптомов кардиомиопатии.
        Причины смерти могут включать сердечную недостаточность, внезапную
        смерть, почечную недостаточность, инфекцию, кахексию <sup>1,3</sup>.
        <br />
        <br />
        Существует патогенетическое и симптоматическое лечение ATTR‑амилоидоза.
        Чтобы узнать, как невролог может помочь пациентам с опасным
        заболеванием, регистрируйтесь в проекте, посвященном диагностике и
        лечению ATTR‑амилоидоза. После регистрации вам будут доступны алгоритмы
        ведения пациентов, видео-лекции с ведущими экспертами, разборы
        клинических случаев и другая полезная информация.
        <a
          onclick="ym(91468266,'reachGoal','lechenie')"
          v-if="user && Object.keys(user).length"
          href="https://amyloidosis.az-most.ru/"
          class="mt-4 button button_pink px-8"
        >
          Узнать больше о лечении ATTR‑амилоидоза
        </a>
        <a v-else :href="redirectLink('')" onclick="ym(91468266,'reachGoal','lechenie')" class="mt-4 button button_pink px-8">
          Узнать больше о лечении ATTR‑амилоидоза
        </a>
      </div>
      <br />

      <div class="epicentral__title">3. Клинические истории</div>
      <br />
      <br />
      <div class="banners__text">
        Пациентка 48 лет. Стала обращать внимание на периодическое онемение,
        жжение, уменьшение чувствительности I-III пальцев обеих рук. Данные
        жалобы появились около 12 месяцев назад и постепенно нарастали.
        Обращалась к неврологу, был установлен диагноз: двусторонний синдром
        запястного канала (туннельный синдром).
        <br />
        <br />
        Выполнено оперативное вмешательство: двусторонняя резекция поперечной
        связки и декомпрессия срединного нерва. После реабилитационного периода,
        несмотря на соблюдение всех рекомендаций сохранялись жалобы на нарушение
        чувствительности I-III пальцев обеих кистей, онемение, чувство жжения.
        Спустя 8 месяцев после оперативного лечения снизилась двигательная
        активность: стало трудно держать ручку/карандаш, открывать ключом замок,
        часто роняла предметы.
        <br />
        <br />
        Как вы думаете, почему лечение оказалось неэффективным? Что мог сделать
        невролог?
        <br />
        <br />
        Регистрируйтесь в проекте, посвященном диагностике и лечению
        ATTR‑амилоидоза в практике врача-невролога. Это поможет избежать ошибок
        и продлить жизнь пациентам с редким заболеванием.
        <a
           onclick="ym(91468266,'reachGoal','clin')"
          v-if="user && Object.keys(user).length"
          href="https://amyloidosis.az-most.ru/#three"
          class="mt-4 button button_pink px-8"
        >
          Разобрать клинические случаи
        </a>
        <a
        onclick="ym(91468266,'reachGoal','clin')"
          v-else
          :href="redirectLink('#three')"
          class="mt-4 button button_pink px-8"
        >
          Разобрать клинические случаи
        </a>
      </div>
      <br />
      <div class="banners__title">Источники:</div>
      <div class="banners__text">
        1. Hawkins P.N., Ando Y., Dispenzeri A., et al. Evolving landscape in
        the management of transthyretin amyloidosis. Ann Med.
        2015;47(8):625-638.
        <br />
        2. Gertz M., Adams D., Ando Y. et al. Avoiding misdiagnosis: expert
        consensus recommendations for the suspicion and diagnosis of
        transthyretin amyloidosis for the general practitioner. BMC Fam Pract.
        2020;21:198. doi: 10.1186/s12875-020-01252-4.
        <br />
        3. Kittleson M.M., Maurer M.S., Ambardekar A.V. et al. Cardiac
        amyloidosis: evolving diagnosis and management. Circulation.
        2020;142(1):e7-e22.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BannersPage",
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    redirectLink(anchor) {
      return `${process.env.VUE_APP_SSO_HOST}signin?source=${encodeURIComponent(
        window.location.href
      )}&next_url=${
        window.location.origin
      }/az-sso&back_url=${encodeURIComponent(
        "https://amyloidosis.az-most.ru" + anchor
      )}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.epicentral {
  &-banner {
    position: relative;
    width: 100%;
    margin-top: 32px;

    @media screen and (max-width: 767px) {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
      }
    }

    &__img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }

    &__text {
      position: absolute;
      top: 50%;
      left: 64px;
      transform: translateY(-50%);
      z-index: 2;
      max-width: 40%;

      @media screen and (max-width: 1219px) {
        max-width: 50%;
        left: 32px;
      }

      @media screen and (max-width: 767px) {
        max-width: calc(100% - 32px);
        left: 16px;
      }
    }

    &__title {
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      color: #fff;

      @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &__description {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: rgb(201, 199, 199);

      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &__title {
    margin-top: 40px;
    color: #830051;
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
  }
}
.banners {
  padding-top: 48px;

  &__title {
    margin-top: 40px;
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;

    & b {
      font-weight: 700;
    }

    sub,
    sup {
      vertical-align: super;
      font-family: "Roboto", sans-serif;
      font: inherit;
      font-size: small;
      color: inherit;
      white-space: nowrap;
    }
  }
}
.nevrology__body-item {
  &-banner {
    width: 100%;
    height: auto;
    max-width: 700px;
    border-radius: 32px;
    overflow: hidden;
    position: relative;
    margin-bottom: 24px;

    @media screen and (max-width: 1220px) {
      width: auto;
      min-height: 116px;
      border-radius: 20px;
    }

    &-img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }

    &-content {
      padding: 32px 0 32px 32px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      & > .button {
        margin-top: auto;
      }
    }

    &-title {
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
      color: #ffffff;

      @media screen and (max-width: 1220px) {
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 26px;
        color: #ffffff;

        & > br {
          display: none;
        }
      }
    }
  }
}
</style>